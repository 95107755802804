import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Carousel from '../components/carousel';
import Img from 'gatsby-image';
import Markdown from 'react-markdown';
const IndexPage = ({ data: { page, site } }) => {
  const { edges: [pageData] } = page;
  const { node: { frontmatter: { images, profil, intro, intro_title, intro_subtitle } } } = pageData;

  function linkTo (slug) {
    window.location.assign(slug);
  }

  function textRender (props) {
    return <p className={'is-size-5  has-text-left-mobile'}>{props.children}</p>;
  }

  return (
    <Layout title={site.siteMetadata.title}>
      <SEO title={`${site.siteMetadata.title} Home`} description={'Gemälde, Skulpturen & Moderene und Zeitgenössische Kunst, Beratung, Konservierung und Restaurierung.'}/>
      <Carousel images={images} />
      <section className="section">
        <div className={'columns'}>
          <div className={'column is-11 is-offset-1'}>
            <h1 className="title">{intro_title || ''}</h1>
            <h2 className="subtitle content">
              <Markdown source={intro_subtitle} />
            </h2>
          </div>
        </div>
        <div className="columns">
          {profil &&
          <div className="column is-4 is-offset-1">
            <figure className="image">
              <Img fluid={(profil.childImageSharp && profil.childImageSharp.fluid)} alt="" objectFit="cover"/>
            </figure>
          </div>
          }
          {intro &&
          <div className="column is-6">
            <div className={'content'}>
              <Markdown source={intro} renderers={{ paragraph: textRender }}/>
            </div>
          </div>
          }
        </div>
      </section>

    </Layout>
  );
};
export default IndexPage;

export const pageQuery = graphql`
    query IndexPage {
        site {
            siteMetadata {
                title
            }
        }
        page: allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "index" } } }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        intro
                        intro_title
                        intro_subtitle
                        profile {
                            childImageSharp {
                                fluid(maxWidth: 1024, , quality: 75) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        images {
                            text
                            image {
                                id
                                childImageSharp {
                                    fluid(maxWidth: 1048, quality: 75) {
                                        ...GatsbyImageSharpFluid_withWebp,
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
